











import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component({
  name: 'CmsCheckbox'
})
export default class Checkbox extends Vue {
  @Prop({type: String}) id: string = '';
  @Prop({type: Boolean, default: false}) readonly checked: boolean = false;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

  handleChange(e: any) {
    this.$emit('change', e.target.checked);
  }
}
